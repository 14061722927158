import dayjs from 'dayjs'
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat)
import Config from 'config'
import { getFields, getFirstItemFromField, isMatchType } from 'cms/fields/_helpers/helpers'
import { isStringNotEmpty } from 'cms/utils/empty-exists'

const HomeEventResultItem = (props) => {
    
    const { model } = props
    const { title, path } = model
    const isEvent = isMatchType(model, 'model.event')
    const isMeeting = isMatchType(model, 'model.meeting')
    const fields = getFields(model)
    const { meeting_date, event_date, start_time, end_time } = fields
    const meeting_date_item = getFirstItemFromField(meeting_date)
    const event_date_item = getFirstItemFromField(event_date)
    const start_time_item = getFirstItemFromField(start_time)
    const end_time_item = getFirstItemFromField(end_time)
    const meeting_date_value = meeting_date_item?.value?.date
    const event_date_value = event_date_item?.value?.date
    const start_time_value = start_time_item?.value?.date
    const end_time_value = end_time_item?.value?.date
    const time_display_format = Config.dates.display.time
    const date_display_format = Config.dates.display.date_short
    
    const formatted_event_date = event_date_value
        ? dayjs(event_date_value, 'YYYY-MM-DD').format(date_display_format)
        : ''
    const formatted_meeting_date = meeting_date_value
        ? dayjs(meeting_date_value, 'YYYY-MM-DD').format(date_display_format)
        : ''
    const formatted_start_time = start_time_value
        ? dayjs.utc(start_time_value, 'hh:mm A').format(time_display_format)
        : ''
    
    const hasEnd = isStringNotEmpty(end_time_value)
    let formatted_end_time = ''
    if (hasEnd) {
        const _end_time_value_dayjs = dayjs(end_time_value, 'hh:mmA')
        const _end_time_value = _end_time_value_dayjs.format(time_display_format)
        formatted_end_time = ` - ${_end_time_value}`
    }
    const showTime = isStringNotEmpty(start_time_value)
        ? ` @ ${formatted_start_time} ${formatted_end_time}`
        : ''
    let formatted_date = ''
    if (isEvent) {
        formatted_date = `${formatted_event_date}${showTime}`
    } else if (isMeeting) {
        formatted_date = `${formatted_meeting_date}${showTime}`
    }
    return (
        <div className='feed-component-events'>
            <div className='feed-component-title'>
                <a href={path}>
                    {title}
                </a>
            </div>
            <div className='feed-component-date'>
                {formatted_date}
            </div>
        </div>
    )
}

export { HomeEventResultItem }
