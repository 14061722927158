import React, {useState, useEffect} from 'react'
import { SimpliCitySDK } from 'cms/sdk'
import { isObject, isStringNotEmpty, objectHasPropertyString } from 'cms/utils/empty-exists'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import BulletPointLink from './styles/bullet-point'
import CTALink from './styles/cta-link'
import TextLink from './styles/text-link'
import UnderlineLink from './styles/underline-link'

const Link = (props) => {
    
    const { link: linkObj, style, format } = props || {}
    const [link, setLink] = useState()
    const {token} = useAuth()
    const isUnderlineLink = format === 'underline-link'
    const isBulletpointLink = format === 'bullet-point-link'
    const isTextLink = format === 'text-link'
    const isCTALink = format === 'cta-link'
    
    const loadLink = async (guid) => {
        if (guid && guid.length > 30) {
            const _link = await SimpliCitySDK.link.findOne(token, guid)
            setLink(_link)
            return _link
        }
    }
    useEffect(() => {
        const load = async (guid) => {
            if (isStringNotEmpty(guid)) {
                const _link = await loadLink(guid)
                setLink(_link)
            }
        }
        // load once
        if (!isObject(link) || !objectHasPropertyString(link, 'guid')) {
            load(linkObj?.link)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [linkObj.link])
    
    return (
        <>
            {link && isUnderlineLink &&
                <UnderlineLink
                    link={link}
                    style={style}
                    format={format}
                />
            }
            {link && isBulletpointLink &&
                <BulletPointLink
                    link={link}
                    style={style}
                    format={format}
                />
            }
            {link && isTextLink &&
                <TextLink
                    link={link}
                    style={style}
                    format={format}
                />
            }
            {link && isCTALink &&
                <CTALink
                    link={link}
                    style={style}
                    format={format}
                />
            }
        </>
    )
}

export default Link
