// import dayjs from 'dayjs'
import Config from 'config'
import { Date } from 'cms/fields/dates/date'
import { getFields } from 'cms/utils/values-settings'

const HomeResultItem = (props) => {
    
    const { model } = props
    const {
        title,
        // description,
        // updated_at,
        // created_at,
        path
    } = model
    const { date } = getFields(model)
    const dateFormat = Config.dates.display.date_short
    // const date = dayjs(updated_at).format(Config.dates.display.date_new)
    
    return (
        <div>
            <div className="feed-component-date">
                {/*<span style={{color: 'red'}}>{_date}</span>*/}
                <Date field={date} format={dateFormat} />
            </div>
            <div className="feed-component-title">
                <a href={path}>
                    {title}
                </a>
            </div>
        </div>
    )
}

export { HomeResultItem }
