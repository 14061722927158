import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CTALink = ({ link, style }) => {
    return (
        <li>
            <a
                href={link?.url}
                className={`cta-link ${style} `}
                target={link?.is_external ? '_blank' : '_self'}
                rel='noreferrer'
            >
                {link?.text}
                {link?.is_external ? (
                    <FontAwesomeIcon icon={`fa-regular fa-arrow-up-right-from-square`} />
                ) : (
                    <FontAwesomeIcon icon={`fa-regular fa-arrow-right`} />
                )}
            </a>
        </li>
    )
}

export default CTALink
