import dayjs from 'dayjs'
import Config from 'config'
// import { getPrimaryDate, hasPrimaryDate } from "cms/models/helpers"

const ResultItem = (props) => {
    
    const { model } = props
    const {
        // Id,
        Path,
        Subject,
        StartTime,
        Description
    } = model
    
    const startTime = dayjs(StartTime).format(Config.dates.display.date_fullday_with_time)
    
    return (
        <div className="feed-result-row">
            <h3 className="title">
                <a href={Path}>
                    {Subject}
                </a>
            </h3>
            {startTime &&
                <div className="field field-name-post-date" style={{fontWeight:700}}>
                    {startTime}
                </div>
            }
            <div className="feed-result-description">
                <p>{Description}</p>
            </div>
        </div>
    )
}

export { ResultItem }
