import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const TextLink = ({ link, style }) => {
    return (
        <li>
            <a
                href={link?.url}
                className={`text-link ${style} `}
                target={link?.is_external ? '_blank' : '_self'}
                rel='noreferrer'
            >
                {link?.text} {link?.is_external &&
                    <FontAwesomeIcon icon={`fa-regular fa-arrow-up-right-from-square`} />
                }
            </a>
        </li>
    )
}

export default TextLink
