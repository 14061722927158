import Config from 'config'
import { useEffect, useState } from 'react'
import { useSimpliCity } from 'cms/hooks/use-simplicity'
import { convertToHtml, DEFAULT_HTML } from 'cms/rich-text/rich-text-utils'
import { Logger } from 'cms/utils/logger'

/**
 * Render richtext content to HTML
 * @param {object} props
 * @param {any} props.richtext
 * @param {string} props.className
 * @returns 
 */
const RichTextToc = (props) => {
    
    const {
        richtext,
        className = '',
    } = props
    
    const [html,setHtml] = useState()
    const dynamicOptions = {
        CustomHeading: {
            enableTOC: false
        }
    }
    const { model, hasModelFeature } = useSimpliCity()
    const { features } = model || {}
    useEffect(() => {
        // Logger.debug('features',features)
        if (model) {
            try {
                if (!richtext) {
                    setHtml(DEFAULT_HTML)
                    return
                }
                if (hasModelFeature('toc')) {
                    const { toc } = features || {}
                    dynamicOptions.CustomHeading.enableTOC = toc
                    const html = convertToHtml(richtext, dynamicOptions)
                    setHtml(html)
                } else {
                    const html = convertToHtml(richtext, dynamicOptions)
                    setHtml(html)
                }
            } catch (error) {
                Logger.error(error)
                Logger.flush()
                setHtml(DEFAULT_HTML)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [model])
    
    const debugStyle = Config.useDebug ? { border: '1px solid red' } : {}
    
    return (
        <div
            style={debugStyle}
            className={`rich-text-container ${className}`}
            dangerouslySetInnerHTML={{
                __html: html,
            }}
        />
    )
}

export { RichTextToc }
