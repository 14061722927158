import { useState } from 'react'
import Meta from 'cms/shared/meta'
import { SimpliCityPreview } from 'cms/shared/simplicity-preview'
import { SimpliCityAppContext } from './simplicity-app.context'
import { useFetchNavigation } from 'cms/hooks/use-fetch-navigation'
import { objectHasPropertyBoolean } from 'cms/utils/empty-exists'
import { registerLicense } from '@syncfusion/ej2-base'
import Config from 'config'
// import { useAlerts } from 'cms/hooks/use-alerts'

/**
 * @param {object} props 
 * @param {any} props.website 
 * @param {import("react").ReactNode} props.children 
 * @returns 
 */
export const SimpliCityContextProvider = (props) => {
    
    const domain = window.location.host
    const path = window.location.pathname
    const {
        website,
        settings,
        children
    } = props
    
    const [error, setError] = useState(null)
    
    const { home } = website
    const { menu, levelOneData, nav, side } = useFetchNavigation(home, setError)
    
    const [webpage, setWebpage] = useState()
    const [model, setModel] = useState()
    const [hasModel, setHasModel] = useState(false)
    const [loading, setLoading] = useState(true)
    const [fields, setFields] = useState()
    const [errorWebpage, setErrorWebpage] = useState(null)
    const [errorModel, setErrorModel] = useState(null)
    const [errorFields, setErrorFields] = useState(null)
    const [hasErrorPage, setHasErrorPage] = useState(false)
    
    // const { alertLoaded, loadAlerts } = useAlerts()
    // useEffect(() => {
    //     if (!alertLoaded) {
    //         loadAlerts()
    //     }
    // }, [alertLoaded])
    
    const hasModelFeature = (key) => {
        const { features } = model || {}
        return objectHasPropertyBoolean(features, key)
    }

    /** @type {SimpliCityAppContext} */
    const appContext = {
        website,
        home,
        domain,
        path,
        webpage,
        setWebpage,
        model,
        setModel,
        hasModel,
        setHasModel,
        loading,
        setLoading,
        fields,
        setFields,
        error,
        setError,
        settings,
        hasModelFeature,
        menu,
        levelOneData,
        nav,
        side,
        errorWebpage,
        errorModel,
        errorFields,
        hasErrorPage,
        setErrorWebpage,
        setErrorModel,
        setHasErrorPage,
        setErrorFields,
    }
    registerLicense(Config.SyncFusion.licence)

    return (
        <SimpliCityAppContext.Provider value={appContext}>
            <SimpliCityPreview>

                <Meta />

                {children}

            </SimpliCityPreview>
        </SimpliCityAppContext.Provider>
    )
}
