import React from 'react'
import { ContentZoneDisplay } from 'cms/components'
import { FieldDisplayWrapper } from 'cms/fields/_helpers/field-display-wrapper'
import { isArrayNotEmpty } from 'cms/utils/empty-exists'

const Component = (props) => {
    
    const {
        field,
        webpage,
    } = props || {}
    const { values } = field || {}

    return (
        <FieldDisplayWrapper field={field}>
            {isArrayNotEmpty(values) &&
                values.map((item, index) => {
                    const { value, settings } = item || {}
                    if (value) {
                        return (
                            <ContentZoneDisplay
                                key={index}
                                webpage={webpage}
                                value={value}
                                settings={settings}
                            />
                        )
                    } else {
                        return null
                    }
                })
            }
        </FieldDisplayWrapper>
    )
}
export { Component }
