import { createContext, useEffect, useReducer } from 'react'
import { useCookies } from 'react-cookie'
import PropTypes from 'prop-types'
import { jwtDecode } from 'jwt-decode'
import axios from 'axios'
import { LOGIN, LOGOUT } from 'cms/auth/auth/store/reducers/actions'
import authReducer from 'cms/auth/auth/store/reducers/auth'
import LoaderWrapper from 'cms/auth/components/loader-wrapper'
import Config from 'config'
import { Logger } from 'cms/utils/logger'


const API_BASEURL = Config.API_BASEURL
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    token: null
}

/**
 * @type {{ token?: string }}
 */
const defaultContext = null

const SimpliCityAuthContext = createContext(defaultContext)

export const SimpliCityAuthProvider = (props) => {

    const { children } = props
    const [state, dispatch] = useReducer(authReducer, initialState)
    const [cookies, setCookie, removeCookie] = useCookies('simplicity_admin')
    
    const verifyToken = (serviceToken) => {
        if (!serviceToken) {
            return false
        }
        const decoded = jwtDecode(serviceToken)
        /**
         * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
         */
        const isValid = decoded.exp > Date.now() / 1000
        // Logger.debug(`Verify token: ${serviceToken}`, isValid)
        return isValid
    }

    const setSession = (serviceToken) => {
        if (serviceToken) {
            Logger.debug('SET SERVICE TOKEN')
            Logger.flush()
            // Logger.debug('SET SERVICE TOKEN: ' + serviceToken)
            setCookie('simplicity_admin', serviceToken,  { path: '/', domain: '.simplicitycms.ca' })
            // localStorage.setItem('serviceToken', serviceToken)
            axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`
        } else {
            Logger.debug('SET SERVICE TOKEN: MISSING' )
            Logger.flush()
            removeCookie('simplicity_admin')
            // localStorage.removeItem('serviceToken')
            delete axios.defaults.headers.common.Authorization
        }
    }

    useEffect(() => {

        const init = async () => {
            try {
                // const path = window.location.pathname
                const serviceToken = cookies["simplicity_admin"]
                // Logger.debug(`init :: Verify token: ${serviceToken}`)
                // Logger.debug(`init :: path: ${path}`)
                if (serviceToken && verifyToken(serviceToken)) {
                    // Logger.debug(`Running init (check session): ${serviceToken}`)
                    // setSession(serviceToken)
                    const response = await axios({
                        method: 'GET',
                        url: `${API_BASEURL}/admin/account/me`,
                        headers: { Authorization: `Bearer ${serviceToken}`},
                        timeout: 8000,
                        responseType: 'json'
                    })

                    const user = response.data
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user,
                            token: serviceToken
                        }
                    })

                } else {
                    const login_url = `${Config.LOGIN_URL}`
                    Logger.debug(`Login: ${login_url}`)
                    Logger.flush()
                    window.location.replace(login_url)
                    // dispatch({
                    //     type: LOGOUT
                    // });
                }
            } catch (err) {
                Logger.error(err);
                // Logger.debug(`Login: ${Config.LOGIN_URL}`)
                // window.location.replace(Config.LOGIN_URL)
                // dispatch({
                //     type: LOGOUT
                // });
            }
        };

        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location])

    const logout = () => {
        Logger.debug("logout taking place")
        Logger.flush()
        removeCookie('simplicity_admin', { path: '/', domain: '.simplicitycms.ca' })
        setSession(null)
        dispatch({type: LOGOUT})
    }

    const getToken = () => {
        const serviceToken = cookies["simplicity_admin"]
        return serviceToken
    }

    const resetPassword = async () => {}

    const updateProfile = () => {}

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <LoaderWrapper/>
    }

    return <SimpliCityAuthContext.Provider
        value={{
            ...state,
            // login,
            logout,
            // register,
            resetPassword,
            updateProfile,
            getToken,
        }}
        >
            {children}
        </SimpliCityAuthContext.Provider>
}

SimpliCityAuthProvider.propTypes = {
    children: PropTypes.node
}

export default SimpliCityAuthContext
