import { isLatLng } from 'cms/forms/utils/is-lat-lng'
import Config from 'config'
import L from 'leaflet'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Marker, Popup, useMapEvents } from 'react-leaflet'
import { useUpdateEffect } from 'react-use'
import { Logger } from 'cms/utils/logger'

/**
 * @type {import('react').FC<DraggableMarkerProps>}
 */
const DraggableMarker = (props) => {

    const {
        defaultLocation,
        onChange
    } = props

    const [draggable, setDraggable] = useState(true)

    const [position, setPosition] = useState(() => {
        /** @type {LatLng} */
        const applicationDefaultLocation = Config.location.default
        const hasLocation = isLatLng(defaultLocation)

        const initialLocation = hasLocation ?
            defaultLocation :
            applicationDefaultLocation

        return initialLocation
    })

    useUpdateEffect(
        () => {
            onChange(position)
        },
        [position]
    )

    /**
     * @type {import('react').MutableRefObject<import('leaflet').Marker | null>}
     */
    const markerRef = useRef(null)

    const mapE = useMapEvents({
        click (event) {
            mapE.locate()
            const { lat, lng } = event.latlng

            setPosition([lat, lng])
        },
        locationfound: (location) => {
            Logger.debug("map location found:", location)
            Logger.flush()
        },
    })

    const eventHandlers = useMemo(
        () => {
            /**
             * @type {L.LeafletEventHandlerFnMap}
             */
            const handlers = {
                dragend () {
                    const marker = markerRef.current

                    if (marker !== null) {
                        const { lat, lng } = marker.getLatLng()
                        setPosition([lat, lng])
                    }
                },
            }

            return handlers
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )
    const toggleDraggable = useCallback(() => {
        setDraggable((d) => !d)
    }, [])

    const icon = L.icon({
        iconUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png",
    })

    return (
        <Marker
            draggable={draggable}
            eventHandlers={eventHandlers}
            position={position}
            icon={icon}
            ref={markerRef}
        >
            <Popup maxWidth={180}>
                <span
                    onClick={toggleDraggable}
                    style={{ wordWrap: "break-word" }}
                >
                    lat: {position[0]} <br />
                    lng: {position[1]}
                </span>
            </Popup>
        </Marker>
    )
}

export { DraggableMarker }

/**
 * @typedef {object} DraggableMarkerProps
 * @property {LatLng=} defaultLocation
 * @property {(loc: LatLng) => void} onChange
 */
