/*
 * Copyright (C) 2024 SimpliCity Digital Inc - All Rights Reserved
 */
import { useEffect, useState } from 'react'
import { ThemeProvider } from '@mui/material'
import { SimpliCityAuthProvider } from 'cms/auth/auth/simplicity-auth-context'
import { SimpliCityNoAuthProvider } from 'cms/auth/noauth/simplicity-auth-context'
import { SimpliCityContextProvider } from 'cms/context'
import { UserWayPlugin } from 'cms/integration/userway/userway'
import { LoadWebsite } from 'cms/shared/load-website'
import { RouterProvider } from 'react-router-dom'
import { theme } from 'theme'
import { router } from 'router/router'
import Config from './config'
import { Logger } from 'cms/utils/logger'

import 'styles/app-styles'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { SkipDuringPrerender } from "cms/shared/hyrdation/avoid-prerender"

const SimpliCityApp = () => {
    
    const [error, setError] = useState()
    const [website, setWebsite] = useState()
    const [settings, setSettings] = useState()
    const isPreview = Config.isPreview
    // const isPrerendering = Config.isPrerendering()
    
    useEffect(() => {
        // console.log(error)
        if (error) {
            debugger
            Logger.error(error)
        }
    }, [error])
    
    if (isPreview) {
        return (
            <GoogleReCaptchaProvider reCaptchaKey={Config.recaptcha_site_key}>
                <ThemeProvider theme={theme}>
                    
                    <SimpliCityAuthProvider>
                        {/* TODO: think more about error handling display */}
                        <LoadWebsite
                            setError={setError}
                            setWebsite={setWebsite}
                            setSettings={setSettings}
                        />
                        {/*  Show an empty skeleton while data is loading  */}
                        {website &&
                            <SimpliCityContextProvider
                                website={website}
                                settings={settings}
                            >
                                {/*   The Router will pass processing context to the   */}
                                {/*   route *element* that matches the request         */}
                                <RouterProvider router={router} />
                            </SimpliCityContextProvider>
                        }
                    </SimpliCityAuthProvider>
                    <SkipDuringPrerender><UserWayPlugin /></SkipDuringPrerender>
                </ThemeProvider>
            </GoogleReCaptchaProvider>
        )
    } else {
        return (
            <GoogleReCaptchaProvider reCaptchaKey={Config.recaptcha_site_key}>
                <ThemeProvider theme={theme}>
                    
                    <SimpliCityNoAuthProvider>
                        {/* TODO: think more about error handling display */}
                        <LoadWebsite
                            setError={setError}
                            setWebsite={setWebsite}
                            setSettings={setSettings}
                        />
                        {/*  Show an empty skeleton while data is loading  */}
                        {website &&
                            <SimpliCityContextProvider
                                website={website}
                                settings={settings}
                            >
                                {/*   The Router will pass processing context to the   */}
                                {/*   route *element* that matches the request         */}
                                <RouterProvider router={router} />
                            </SimpliCityContextProvider>
                        }
                    </SimpliCityNoAuthProvider>
                    <SkipDuringPrerender><UserWayPlugin /></SkipDuringPrerender>
                </ThemeProvider>
            </GoogleReCaptchaProvider>
        )
    }
}
SimpliCityApp.displayName = 'SimpliCityApp'
export { SimpliCityApp }

