import React, { Fragment, useEffect, useState } from 'react'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import Config from 'config'
import { Logger } from 'cms/utils/logger'

const SessionCheck = function (props) {
    const [authenticated, setAuthenticated] = useState('pending')
    const { isLoggedIn } = useAuth()
    let component

    useEffect(() => {
        // Logger.info('isLoggedIn', isLoggedIn)
        // Logger.flush()
        if (isLoggedIn) {
            setAuthenticated('authenticated')
        } else {
            setAuthenticated('not authenticated')
        }
    }, [isLoggedIn])

    if (authenticated === 'authenticated') {
        // console.info('showing requested page (children)')
        component = props.children
    } else if (authenticated === 'not authenticated') {
        Logger.info('showing login')
        Logger.flush()
        const login_url = `${Config.LOGIN_URL}`
        // console.log(`Login: ${login_url}`)
        window.location.replace(login_url)
    } else {
        // console.info('showing empty fragment')
        // TODO: should this be content?
        component = <Fragment />
    }

    return <Fragment>{component}</Fragment>
}

export default SessionCheck
