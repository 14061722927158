import React, { Fragment } from "react";
import { Helmet } from 'react-helmet'
import Config from 'config'
// import { SimpliCityAppContext } from 'cms/context/app'

function Meta () {
    
    // const appContext = useContext(AppContext);
    // const {
    //     showMobileMenu,
    //     setShowMobileMenu
    // } = appContext
    
    const {
        hostBaseUrl,
        SEO
    } = Config
    // FIXME: this is mostly hardcoded information that needs to be replaced.
    
    return (
        <Fragment>
           <Helmet>
                {/* This defines defaults - most will be overridden deeper in */}
                <meta charSet="utf-8" />
                <title>{SEO.website_title}</title>
                <link rel="canonical" href={`${hostBaseUrl}`} />
                <meta name='robots' content='index,follow' />
                <meta name="google-site-verification" content={SEO.google_site_verification} />
                <meta httpEquiv="X-UA-Compatible" content={SEO.x_ua_compatible} />
                <meta httpEquiv="Content-Type" content={SEO.content_type} />
                <meta property="og:title" content={SEO.website_title} />
                <meta property="og:url" content={SEO.url} />
                <meta property="og:site_name" content={SEO.website_title} />
                <meta property="og:description" content={SEO.description} />
                <meta property="og:image" content={SEO.website_image} />
                <meta name="viewport" content={SEO.viewport} />
            </Helmet>
        </Fragment>
    )
}

export default Meta
