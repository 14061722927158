import { useContext } from 'react'
import SimpliCityAuthContext from 'cms/auth/auth/simplicity-auth-context'
import SimpliCityNoAuthContext from 'cms/auth/noauth/simplicity-auth-context'
import Config from 'config'

const useAuth = () => {
    
    const authContext = useContext(SimpliCityAuthContext)
    const noauthContext = useContext(SimpliCityNoAuthContext)
    const context = Config.isPreview ? authContext : noauthContext
    
    if (!context) {
        throw new Error('context must be used inside provider')
    }
    
    return context
};

export { useAuth }
