import React from 'react'
import SessionCheck from 'cms/auth/auth/session-check'
import Config from 'config'

function SimpliCityPreview (props) {
    
    const isPreview = Config.isPreview
    const {children} = props || {}
    
    if (isPreview) {
        // debugger
        return (
            <div className='simplicity-root'>
                
                <SessionCheck>
                    {children}
                </SessionCheck>
                
            </div>
        )
    } else {
        // debugger
        return (
            <div className='simplicity-root'>
                
                {children}
                
            </div>
        )
    }
}
SimpliCityPreview.displayName = 'SimpliCityPreview'
export { SimpliCityPreview }
