/*
 * Copyright (C) 2024 SimpliCity Digital Inc - All Rights Reserved
 */
const CONFIG = {
    home_page_id: '48c25737-29e4-463e-b7ea-b093ddaa4291',
    website_id: '2ad55006-3f62-4bb4-8b9a-6a3225e48e54',
    recaptcha_site_key: '6LenVeEpAAAAAHBfaB6YaiuxZewd_XN-5LkeT_2a',
    isPreview: process.env.REACT_APP_ENABLE_PREVIEW === 'true' || false,
    isPrerendering: () => navigator.userAgent === 'ReactSnap',
    useDebug: false,
    showErrors: true,
    ENV: process.env.REACT_APP_ENV || 'REACT_APP_ENV not set',
    IMAGE_BASEURL: process.env.REACT_APP_SIMPLICITY_SDK_IMAGE_URL,
    API_BASEURL: process.env.REACT_APP_SIMPLICITY_SDK_API_BASE_URL,
    PREVIEW_URL: process.env.REACT_APP_SIMPLICITY_SDK_PREVIEW_URL,
    APPS_HOST: process.env.REACT_APP_APPS_HOST,
    LOGIN_URL: `${process.env.REACT_APP_LOGIN_URL}?redirectUri=${process.env.REACT_APP_REDIRECT_URI}`,
    LOGOUT_URL: process.env.REACT_APP_LOGOUT_URL,
    forms: {
        files: {
            defaultMaxSize: 40 * 1024 * 1024,
            defaultMaxSizeDisplay: '40mb',
        },
    },
    dates: {
        timezone: 'America/Vancouver',
        internalFormats: {
            datetime: 'YYYY-MM-DDTHH:mm:ssZZ', // ... but just use .toISOString() for a datetime
            date: 'YYYY-MM-DD',
            time: 'hh:mm A',
        },
        edit: {
            date: 'MMMM DD, YYYY',
            time: 'hh:mma',
            datetime: 'MMMM D, YYYY h:mma',
        },
        display: {
            date: 'MMMM DD, YYYY',
            date_new: 'dddd, MMM D',
            date_short: 'ddd, MMM D',
            event_date: 'dddd, MMM D',
            date_fullday: 'dddd MMMM D, YYYY',
            date_fullday_with_time: 'dddd MMMM D, YYYY h:mma',
            time: 'h:mma',
            datetime: 'MMMM DD, YYYY h:mma',
        },
    },
    location: {
        default: [49.3539659,-123.0809661]
    },
    SyncFusion: {
        licence: process.env.REACT_APP_SYNCFUSION_LICENCE_KEY
    },
    Recollect: {
        script: { src: 'https://assets.ca.recollect.net/api/widget.js?scripts=head' },
        style: { href: 'https://recollect.a.ssl.fastly.net/0.11.1654194167/api/areas/DistNorthVancouver/services/waste/style/widget/logan/Default.css' }
    },
    Userway: {
        src: 'https://cdn.userway.org/widget.js',
        'data-account': 'unAfj7cv4G'
    },
    FontAwesomeKit: process.env.REACT_APP_FONT_KIT, // dnv: kit-790184f31f, simplicity: kit-db56931b65
    SEO: {
        content_type: 'text/html; charset=utf-8',
        x_ua_compatible: 'IE=edge,chrome=1',
        website_title: 'District of North Vancouver',
        description: 'This is the official website for the District of North Vancouver, in British Columbia, Canada. It contains information on District programs and services, property and development, government business, and more.',
        google_site_verification: '+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34=',
        url: 'https://www.dnv.org/home',
        website_image: 'https://www.dnv.org/sites/default/files/uploads/images/homepage-mar2019.jpg',
        keywords: '',
        viewport: 'width=device-width, initial-scale=1.0',
    },
};
console.log('Built by Simplicity Digital with love.');
console.log(`[ENV = ${CONFIG.ENV}]`);

export default CONFIG;
