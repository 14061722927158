import React from 'react'
// import dayjs from 'dayjs'
import Config from 'config'
import { Date } from 'cms/fields/dates/date'
import { getFields } from 'cms/utils/values-settings'

const HomeNoticeResultItem = (props) => {
    
    const { model } = props
    const {
        title,
        description,
        // updated_at,
        // created_at,
        path
    } = model
    const { date } = getFields(model)
    const dateFormat = Config.dates.display.date_short
    // const _date = dayjs(updated_at).format(dateFormat)
    
    return (
        <div>
            <div className="feed-component-date feed-component-date-notice">
                {/*<span style={{color: 'red'}}>{_date}</span>*/}
                <Date field={date} format={dateFormat} />
            </div>
            <div className="feed-component-title">
                <a href={path}>
                    {title}
                </a>
            </div>
            <div className="feed-component-description">
                {description}
            </div>
        </div>
    )
}

export { HomeNoticeResultItem }
