import { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { content } from './carousel-data'

const HomeBanner = () => {
    
    const [sectionIdx, setSectionIdx] = useState(0)
    const [currentSection, setCurrentSection] = useState(null)

    useEffect(() => {
        setCurrentSection(content[sectionIdx]);
    }, [sectionIdx])

    function moveIdx (increment) {
        let idx = sectionIdx + increment
        if (idx > content.length - 1) {
            idx = 0
        }
        if (idx < 0) {
            idx = content.length - 1
        }
        setSectionIdx(idx)
    }

    return (
        <div className="home-banner">

            <div className="home-banner-image"
                style={{ backgroundImage: `url(${currentSection?.image})` }}
            />

            <div className="home-banner-content">
                
                <h1>{currentSection?.title}</h1>
                
                <p>{currentSection?.text}</p>

                <div className="controls">
                    
                    <Link to={currentSection?.link}>{currentSection?.linkText}</Link>
                    
                    <div className="forward-back">
                        <button
                            aria-label='Previous'
                            onClick={() => moveIdx(-1)}
                        >
                            <FontAwesomeIcon icon={`fa-regular fa-arrow-left`} />
                        </button>

                        <span>{`${sectionIdx + 1}/${content.length}`}</span>

                        <button
                            aria-label='Next'
                            onClick={() => moveIdx(1)}
                        >
                            <FontAwesomeIcon icon={`fa-regular fa-arrow-right`} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { HomeBanner }
