// TODO: NOTE: To swap kits - change both
//  - and make sure the correct fontawesome auth token is available in env vars (.env.yarn)
//  - change the package dependency to the correct kit
//  - and the correct version of that kit (not the same between kits)
//  - and use the matching kit import here
//
//  "@awesome.me/kit-790184f31f": "^1.0.40",
//  "@awesome.me/kit-db56931b65": "^1.0.67",
//

// import * as SimplicityIcons from './icons-fontawesome-simplicity'
// const icons = SimplicityIcons

import * as DNVIcons from './icons-fontawesome-dnv'
import { brands, solid, regular, all } from './icons-fontawesome-dnv'
const icons = DNVIcons
const { getIcon } = DNVIcons

export { icons, getIcon, brands, solid, regular, all }





// For testing only with both kits
//           WARNING: using this will import both kits
// import Config from 'config'
// import * as SimplicityIcons from './icons-fontawesome-simplicity'
// import * as DNVIcons from './icons-fontawesome-dnv'
// // Will use DNV icons if config (env var) is missing
// const icons = Config.FontAwesomeKit === 'kit-db56931b65'
//     ? SimplicityIcons
//     : DNVIcons
// export { icons }
