import React from 'react'
import Link from './link'
import Icon from './icon'

const IconLinkListComponent = (props) => {
    
    const { component } = props || {}
    const { icon_links = [], title, settings } = component || {}
    const { style, format } = settings || {}
    
    return (
        <div className='link-list'>
            {title &&
                <h2>{title}</h2>
            }
            {icon_links.map((icon_link, idx) => (
                <div
                    key={idx}
                    style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}
                >
                    <Icon
                        icon={icon_link?.icon}
                        format={format}
                        style={style}
                    />
                    <Link
                        link={icon_link}
                        format={format}
                        style={style}
                    />
                </div>
            ))}
        </div>
    )
}

IconLinkListComponent.displayName = 'IconLinkListComponent'
export { IconLinkListComponent }
