/*
 * Copyright (C) 2024 SimpliCity Digital Inc - All Rights Reserved
 */
import { useExternalScript } from 'cms/hooks/use-external-script'
import Config from 'config'
import { Logger } from 'cms/utils/logger'

const UserWayPlugin = () => {
    
    const attributes = Config.Userway
    const { src: script } = attributes
    
    const status = useExternalScript(script, attributes)
    // const scriptLoading = status === 'loading'
    const scriptReady = status === 'ready'
    
    if (scriptReady) {
        // Logger.debug(`loaded UserWay script plugin`)
    }
    Logger.flush()
    return (
        <></>
    )
}
UserWayPlugin.displayName = 'UserWayPlugin'
export { UserWayPlugin }

