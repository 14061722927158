import Config from 'config'

export const DATE_DISPLAY_FORMAT = Config.dates.display.date
export const DATE_STORAGE_FORMAT = Config.dates.internalFormats.date

export const DATE_SCHEMA = {
    'field.format.numeric': {
        "name":"YYYY-MM-DD (2000-01-30)",
        "description":"Numeric",
        "value":"field.format.numeric",
        "format":"YYYY-MM-DD"
    },
    'field.format.short': {
        "name":"DD MMM (30 Jan)",
        "description":"Short",
        "value":"field.format.short",
        "format":"DD MMM"
    },
    'field.format.shortyear': {
        "name":"DD MMM YYYY (30 Jan 2000)",
        "description":"Short+Year",
        "value":"field.format.shortyear",
        "format":"DD MMM YYYY"
    },
    'field.format.business': {
        "name":"dddd, MMMM D (Monday, January 1)",
        "description":"Business",
        "value":"field.format.business",
        "format":"dddd, MMMM D"
    },
    'field.format.businessyear': {
        "name":"dddd, MMMM D, YYYY (Monday, January 1, 2000)",
        "description":"Business+Year",
        "value":"field.format.businessyear",
        "format":"dddd, MMMM D, YYYY"
    },
    'field.format.none': DATE_DISPLAY_FORMAT,   // use default
}

// FIXME: need to match the 'value' property of real schema and get 'format' from real schema
export const DATE_FORMATS = {
    'field.format.numeric': DATE_SCHEMA['field.format.numeric'].format,
    'field.format.short': DATE_SCHEMA['field.format.short'].format,
    'field.format.shortyear': DATE_SCHEMA['field.format.shortyear'].format,
    'field.format.business': DATE_SCHEMA['field.format.business'].format,
    'field.format.businessyear': DATE_SCHEMA['field.format.businessyear'].format,
    'field.format.none': DATE_DISPLAY_FORMAT,   // use default
}
export const getDateFormat = (format) =>  {
    let formatDef = DATE_FORMATS[format]
    if (!formatDef) {
        formatDef = DATE_DISPLAY_FORMAT
    }
    return formatDef
}




// --------------------- Datetime -------------------------------------------------
export const DATETIME_DISPLAY_FORMAT = Config.dates.display.datetime
export const DATETIME_STORAGE_FORMAT = Config.dates.internalFormats.datetime

export const DATETIME_SCHEMA = {
    'field.format.numeric': {
        "name":"YYYY-MM-DD (2000-01-30)",
        "description":"Numeric",
        "value":"field.format.numeric",
        "format":"YYYY-MM-DD"
    },
    'field.format.short': {
        "name":"DD MMM (30 Jan)",
        "description":"Short",
        "value":"field.format.short",
        "format":"DD MMM"
    },
    'field.format.shortyear': {
        "name":"DD MMM YYYY (30 Jan 2000)",
        "description":"Short+Year",
        "value":"field.format.shortyear",
        "format":"DD MMM YYYY"
    },
    'field.format.business': {
        "name":"dddd, MMMM D (Monday, January 1)",
        "description":"Business",
        "value":"field.format.business",
        "format":"dddd, MMMM D"
    },
    'field.format.businessyear': {
        "name":"dddd, MMMM D, YYYY (Monday, January 1, 2000)",
        "description":"Business+Year",
        "value":"field.format.businessyear",
        "format":"dddd, MMMM D, YYYY"
    },
    'field.format.none': DATETIME_DISPLAY_FORMAT,   // use default
}

// FIXME: need to match the 'value' property of real schema and get 'format' from real schema
export const DATETIME_FORMATS = {
    'field.format.numeric': DATETIME_SCHEMA['field.format.numeric'].format,
    'field.format.short': DATETIME_SCHEMA['field.format.short'].format,
    'field.format.shortyear': DATETIME_SCHEMA['field.format.shortyear'].format,
    'field.format.business': DATETIME_SCHEMA['field.format.business'].format,
    'field.format.businessyear': DATETIME_SCHEMA['field.format.businessyear'].format,
    'field.format.none': DATE_DISPLAY_FORMAT,   // use default
}

export const getDateTimeFormat = (format) =>  {
    let formatDef = DATETIME_FORMATS[format]
    if (!formatDef) {
        formatDef = DATETIME_DISPLAY_FORMAT
    }
    return formatDef
}
