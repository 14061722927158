import { useSimpliCity } from 'cms/hooks/use-simplicity'
import { LayoutSelector } from "layout/layout-selector"
import { Helmet } from "react-helmet"
import { CircularProgress } from '@mui/material'
import { ErrorLayout } from 'cms/layout/error/data-error/error-fullwidth'
// import { ErrorLayout } from 'cms/layout/error/data-error/error-two-column'

const LayoutWrapper = () => {
    
    let {
        website,
        webpage,
        model,
        error,
        loading,
    } = useSimpliCity()
    const url = new URL(webpage?.path, window.location).href
    
    return (
        <>
            {website &&
                <Helmet>
                    <link rel="canonical" href={url} />
                    <meta property="og:url" content={url} />
                </Helmet>
            }
            {model &&
                <Helmet>
                    <title>{model?.title}</title>
                    <meta name='description' content={model?.description} />
                    <meta property="og:title" content={model?.title} />
                    <meta property="og:description" content={model?.description} />
                    {/*  TODO: need to convert to image from guid for tag */}
                    {/*<meta property="og:image" content={model['banner_image']} />*/}
                </Helmet>
            }
            {webpage && model &&
                <LayoutSelector />
            }

            {/* FIXME: no loader fires on page navigation; this controls the first load */}
            {/*{loading && !webpage &&*/}
            {/*    <SkeletonLayout />*/}
            {/*}*/}

            {/*  need a 404 page - also called from router error */}
            {error && !loading &&
                <ErrorLayout />
                // <DefaultError />
            }
            {/*{!error && !webpage &&*/}
            {loading &&
                <div style={{
                    width: '100%',
                    height: '70vh',
                    gap: '24px',
                    display: 'flex', flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '40px'
                }}>
                    <CircularProgress
                        style={{ color: '#003E43' }}  // in CSS var(--color-dnv-bg-and-border-dark-navy)
                    />
                </div>
            }
            
        </>
    )
}

LayoutWrapper.displayName = 'LayoutWrapper'
export { LayoutWrapper }
