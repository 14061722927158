import { useSimpliCity } from 'cms/hooks/use-simplicity'
import { ErrorDisplay } from 'cms/shared/error_display'
import { Header } from 'components/header'
import { HomeServicesBanner } from 'layout/homepage/services-banner'
import { Home_Feeds } from 'layout/homepage/feeds'
import { HomeContent } from 'layout/homepage/content'
import { HomeHighlightArea } from 'layout/homepage/highlight-area'
import { Footer } from 'components/footer'
import { SkipDuringPrerender } from 'cms/shared/hyrdation/avoid-prerender'

const Layout_Homepage = () => {

    const { model, fields, error } = useSimpliCity()

    return (
        <>
            <Header />
            <div>
                
                {fields &&
                    <HomeServicesBanner />
                }
                
                <div id='layout' className='layout homepage'>
                    
                    <Home_Feeds />
                    
                    <div className='homepage-wrapper'>
                        {model &&
                            <HomeContent />
                        }
                        {model &&
                            <HomeHighlightArea />
                        }
                    </div>
                </div>
            </div>
            
            <ErrorDisplay error={error} />
            
            <Footer />
            
        </>
    )
}
Layout_Homepage.displayName = 'Layout_Homepage'
export { Layout_Homepage }
