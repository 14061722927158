import React from 'react'
import CircleIcon from '@mui/icons-material/Circle'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BulletPointLink = ({ link, style }) => {
    return (
        <li>
            <CircleIcon fontSize='small' className={`${style} bullet-point`} />
            <a
                href={link?.url}
                className={`${style} `}
                target={link?.is_external ? '_blank' : '_self'}
                rel='noreferrer'
            >
                {link.text} {link?.is_external &&
                    <FontAwesomeIcon icon={`fa-regular fa-arrow-up-right-from-square`} />
                }
            </a>
        </li>
    )
}

export default BulletPointLink
