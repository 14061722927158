import React from 'react'
import Config from 'config'

function ErrorDisplay (props) {

    const { error } = props

    return (
        error && Config.showErrors &&
            <div className="Error">
                <ul>
                    {error.message}
                </ul>
            </div>
    )
}

export { ErrorDisplay }
