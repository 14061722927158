import React from "react"
import { HomeBanner } from 'layout/homepage/services-banner/carousel'
import { CommonTasks } from 'layout/homepage/services-banner/common-tasks'

const HomeServicesBanner = () => {
    
    return (
        <div className="home-top-section">
            <HomeBanner />
            <CommonTasks />
        </div>
    )
}

export { HomeServicesBanner }
