import Config from 'config'
import { useEffect, useState } from 'react'
import { convertToHtml, DEFAULT_HTML } from 'cms/rich-text/rich-text-utils'
import { Logger } from 'cms/utils/logger'

/**
 * Render richtext content to HTML
 * @param {object} props
 * @param {any} props.richtext
 * @param {string} props.className
 * @returns 
 */
const RichTextSimple = (props) => {
    
    const {
        richtext,
        className = '',
    } = props
    
    const [html,setHtml] = useState()
    const dynamicOptions = {
        CustomHeading: {
            enableTOC: false
        }
    }
    useEffect(() => {
        try {
            if (!richtext) {
                setHtml(DEFAULT_HTML)
                return
            }
            const html = convertToHtml(richtext, dynamicOptions)
            setHtml(html)
        } catch (error) {
            Logger.error(error)
            Logger.flush()
            setHtml(DEFAULT_HTML)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [richtext])
    
    const debugStyle = Config.useDebug ? { border: '1px solid red' } : {}
    
    return (
        <div
            style={debugStyle}
            className={`rich-text-container ${className}`}
            dangerouslySetInnerHTML={{
                __html: html,
            }}
        />
    )
}

export { RichTextSimple }
