import dayjs from 'dayjs'
import Config from 'config'
import { getPrimaryDate, hasPrimaryDate } from 'cms/models/helpers'
import { getFields } from 'cms/utils/values-settings'
import { Number } from 'cms/fields/number'

const ResultItemBylaw = (props) => {
    
    const { model } = props
    const {
        title,
        description,
        path
    } = model
    const { bylaw_number } = getFields(model)
    const hasDate = hasPrimaryDate(model)
    let primary_date
    if (hasDate) {
        const dateData = getPrimaryDate(model)
        // const updated_at = model?.updated_at
        // const { date = updated_at } = dateData || {}
        const { date } = dateData || {}
        primary_date = date ? dayjs(date).format(Config.dates.display.date_fullday) : undefined
    }
    
    return (
        <div className="feed-result-row">
            <h3 className="title">
                <a href={path}>
                    {title}
                </a>
            </h3>
            <div className="field field-name-bylaw-number" style={{fontWeight:700}}>
                Bylaw <Number field={bylaw_number} />
            </div>
            {primary_date &&
                <div className="field field-name-post-date">
                    {primary_date}
                </div>
            }
            <div className="feed-result-description">
                <p>{description}</p>
            </div>
        </div>
    )
}

export { ResultItemBylaw }
