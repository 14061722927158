export const content = [
    {
        image: "https://images.dnv.org/images/girls-at-farm-1277x1920.jpg",
        title: "Join a DNV committee",
        text: `You can make a difference by joining one of our advisory committees and helping to shape the future of the DNV.`,
        link: "/government-administration/volunteer-serve-committee",
        linkText: "Learn more and join a committee",
    },
    {
        image: "https://images.dnv.org/images/historical-dnvfrs-testing-fire-hoses.jpg",
        title: "DNVFRS celebrates 70th anniversary",
        text: `On July 5, 1954, the fledgling District of North Vancouver fire department responded to its first alarm call.`,
        link: "/news/dnvfrs-celebrates-70th-anniversary",
        linkText: "Learn more about DNVFR’s history",
    },
]
