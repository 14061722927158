
const CONFIG = {
    home_page_id: '48c25737-29e4-463e-b7ea-b093ddaa4291',
    website_id: '2ad55006-3f62-4bb4-8b9a-6a3225e48e54',
    ENV: process.env.REACT_APP_ENV || 'REACT_APP_ENV not set',
    IMAGE_BASEURL: process.env.REACT_APP_SIMPLICITY_SDK_IMAGE_URL,
    API_BASEURL: process.env.REACT_APP_SIMPLICITY_SDK_API_BASE_URL,
    PREVIEW_URL: process.env.REACT_APP_SIMPLICITY_SDK_PREVIEW_URL,
    LOGIN_URL: `${process.env.REACT_APP_LOGIN_URL}?redirectUri=${process.env.REACT_APP_REDIRECT_URI}`,
    LOGOUT_URL: process.env.REACT_APP_LOGOUT_URL,
    Search: {
        applicationId: process.env.REACT_APP_SEARCH_APP_ID,
        apiKey: process.env.REACT_APP_SEARCH_API_KEY
    },
    dates: {
        timezone: 'America/Vancouver',
        internalFormats: {
            datetime: 'YYYY-MM-DDTHH:mm:ssZZ', // ... but just use .toISOString() for a datetime
            date: "'YYYY-MM-DD'",
            time: 'hh:mm A',
        },
        edit: {
            date: 'MMMM dd, yyyy',
            time: 'hh:mm a',
            datetime: 'MMMM dd, yyyy hh:mm a',
        },
        display: {
            date: 'MMMM DD, yyyy',
            time: 'hh:mm a',
            datetime: 'MMMM DD, yyyy hh:mm a',
        },
    },
    SEO: {
        content_type: 'text/html; charset=utf-8',
        x_ua_compatible: 'IE=edge,chrome=1',
        website_title: 'District of North Vancouver',
        description: 'This is the official website for the District of North Vancouver, in British Columbia, Canada. It contains information on District programs and services, property and development, government business, and more.',
        google_site_verification: '+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34=',
        url: 'https://www.dnv.org/home',
        website_image: 'https://www.dnv.org/sites/default/files/uploads/images/homepage-mar2019.jpg',
        keywords: '',
        viewport: 'width=device-width, initial-scale=1.0',
    },
    enable_logs: process.env.REACT_APP_ENABLE_LOGS === 'true' || false,
    useDebug: false,
    showErrors: true,
    Logging: {
        SourceToken: process.env.REACT_APP_LOG_SOURCE_TOKEN || 'tJ2uREcbw4nRBTZyryY52PHN', // defaults to simplicity development token
        enableConsole: process.env.REACT_APP_ENABLE_CONSOLE_LOG === 'true' || false,
        enableApiHttp: process.env.REACT_APP_ENABLE_HTTP_LOG === 'true' || false,
        enableSavePipelineLog: process.env.REACT_APP_ENABLE_SAVE_PIPELINE_LOG === 'true' || false,
        enableWebpageLog: process.env.REACT_APP_ENABLE_WEBPAGE_LOG === 'true' || false,
    },
}
console.log('Built by Simplicity Digital with love.')
console.log(`[ENV = ${CONFIG.ENV}]`)

export default CONFIG
